import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios-config';

// Configure chat API using Redux Toolkit's createApi
export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ["CHAT_MESSAGES"],
  endpoints: (builder) => ({
    fetchChatMessages: builder.query({
      query: ({ chatId, params }) => ({
        url: `/chats/${chatId}/messages`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { chatId }) => {
        return [{ type: 'CHAT_MESSAGES', id: chatId }];
      }
    }),
    deleteChat: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/chats/${id}`,
        method: 'DELETE',
        extraOptions: { ...rest }
      }),
      invalidatesTags: (_result, _error, chat) => {
        return [{ type: 'CHAT_MESSAGES', id: chat?.id }];
      }
    })
  })
});

export const updateChatMessagesForId = (chatId, updateFn) => {
  return (dispatch, getState) => {
    // Get all cached queries
    const queries = chatApi.util.selectInvalidatedBy(getState(), [{ type: 'CHAT_MESSAGES', id: chatId }]);

    // Update each matching query
    queries.forEach(query => {
      const { endpointName, originalArgs } = query;
      if (endpointName === 'fetchChatMessages') {
        dispatch(
          chatApi.util.updateQueryData(
            'fetchChatMessages',
            originalArgs,
            updateFn
          )
        );
      }
    });
  };
};

export const { useDeleteChatMutation, useFetchChatMessagesQuery, useLazyFetchChatMessagesQuery } = chatApi;
export default chatApi;
